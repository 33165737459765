<template>
  <div class="search-item-container" @click.prevent="itemClickHandler(item)">
    <div class="cols pad-h--1">
      <div class="search-item--date">
        <div class="text-tetriary">
          {{ item.created_date | parseDate | formatDate }}<br />
          {{ item.created_date | parseDate | formatTime }}
        </div>

        <div>
          <i class="el-icon el-icon-document text-m text-primary" v-if="item.save_results"></i>
          <i class="el-icon el-icon-delete text-m text-tetriary" v-if="item.is_deleted"></i>
        </div>

        <div class="search-item-text text-m">{{ item.last_results_count }} {{ $tt('events') }}</div>
      </div>

      <div class="flex-cell--static" style="width: 1rem"></div>

      <div class="rows flex-cell">
        <div class="mar-bottom--0-5">
          <span>{{ $tf('reason') }}: {{ item.reason }}</span>
        </div>

        <div class="cols" v-if="item.case_face_id">
          <div class="search-item-image-container flex-cell--static" v-if="case_face">
            <img :src="case_face.thumbnail" class="search-item-image-thumbnail" @click.stop="(v) => showImage(case_face.source_photo, null)" />
          </div>
          <div class="flex-cell--static" style="width: 1rem" v-if="case_face"></div>
          <div class="flex-cell rows" style="width: 1rem">
            <div v-if="case_face">
              <div>
                <a :href="getCaseUrl(case_face)" @click.stop target="_blank">{{ $tf('face | id') }}: {{ item.case_face_id }}</a>
              </div>
            </div>
            <div v-else>
              <div>{{ $tf('face | id') }}: {{ item.case_face_id }}</div>
            </div>
          </div>
        </div>

        <div class="cols" v-else-if="item.dossier">
          <div class="search-item-image-container flex-cell--static" v-if="dossier_face">
            <img :src="dossier_face.thumbnail" class="search-item-image-thumbnail" @click.stop="(v) => showImage(dossier_face.source_photo, null)" />
          </div>

          <div class="flex-cell--static" style="width: 1rem" v-if="dossier_face"></div>

          <div class="flex-cell rows" style="justify-content: space-between" v-if="dossier">
            <a v-if="dossier" :href="getDossierUrl(dossier)" class="text-xl search-item-link" @click.stop.prevent="dossierClickHandler(dossier)">
              {{ dossier.name | shortString }}
            </a>
            <div class="search-item-text--large" v-else>{{ $tfo('not_found,,2') }}</div>

            <div v-if="dossier && dossier.dossier_lists" class="dossier-lists-container">
              <span
                class="dossier-list-item text-s"
                :style="{ backgroundColor: '#' + list.color, color: getFontColor(list) }"
                v-for="list in getDossierLists(dossier.dossier_lists)"
              >
                {{ list.name | shortString }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-cell--static cols--center">
        <i v-if="false" class="icon-button flex-cell--static el-icon-view" @click.stop="viewHandler"></i>

        <el-tooltip class="tooltip-item" effect="dark" :content="$tf('edit')" placement="top" :open-delay="800">
          <i class="icon-button flex-cell--static el-icon-edit" :class="{ 'icon-button--disabled': item.is_deleted }" @click.stop="editHandler"></i>
        </el-tooltip>

        <el-tooltip class="tooltip-item" effect="dark" :content="$tf('update')" placement="top" :open-delay="800">
          <i class="icon-button flex-cell--static el-icon-refresh" :class="{ 'icon-button--disabled': item.is_deleted }" @click.stop="refreshHandler"></i>
        </el-tooltip>

        <el-tooltip class="tooltip-item" effect="dark" :content="$tf('delete')" placement="top" :open-delay="800">
          <i class="icon-button flex-cell--static el-icon-delete" :class="{ 'icon-button--disabled': item.is_deleted }" @click.stop="deleteHandler"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import Color from 'color';
import qs from 'qs';
import _ from '@/apps/common/lodash';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  name: 'puppeteer-search-item',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      case_face: null,
      dossier: null,
      dossier_face: null,
      loading: false
    };
  },
  computed: {
    state() {
      return this.$store.state.puppeteer_search;
    }
  },
  watch: {},
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    getFontColor(item) {
      let color = Color('#' + item.color),
        isLight = color.light();
      return isLight ? '#000' : 'rgba(255, 255, 255, 0.8)';
    },
    getDossierLists(v) {
      return v.map((id) => this.$store.state.dossier_lists.items.find((v) => v.id === id));
    },
    load() {
      let { dossier, case_face_id } = this.item;
      if (case_face_id) {
        return this.loadCaseFace(case_face_id);
      } else if (dossier) {
        return this.loadDossier(dossier);
      } else {
        return Promise.resolve(null);
      }
    },
    loadDossier(id) {
      this.loading = true;
      return this.$store
        .dispatch('get_dossiers', { id })
        .then((dossier) => {
          this.dossier = dossier;
          return this.$store.dispatch('getBatchObjectFaces', { dossier: dossier.id });
        })
        .then((dossier_face) => {
          this.dossier_face = dossier_face.results && dossier_face.results[0];
          return true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadCaseFace(id) {
      this.loading = true;
      return this.$store
        .dispatch('get_case-faces', { id })
        .then((v) => {
          this.case_face = v;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getItemUrl(v) {
      let filter = Object.assign({}, this.state.filter.empty, { id: v.id });
      return '#/puppeteer/search/events/filter/' + qs.stringify(filter, { sort: alphabeticalSort });
    },
    getDossierUrl(v) {
      return '#/dossiers/' + v.id;
    },
    itemClickHandler(v) {
      let filter = _.pickBy(Object.assign({}, this.$store.state.puppeteer_search_events.filter.empty, { request: v.id }), (v) => !!v);
      this.$router.push({
        path: '/puppeteer/search/events/filter/' + qs.stringify(filter, { sort: alphabeticalSort })
      });
    },
    dossierClickHandler(v) {
      this.$router.push({ path: '/dossiers/' + v.id });
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    },
    getBox(event) {
      return {
        x: event.frame_coords_left,
        y: event.frame_coords_top,
        w: event.frame_coords_right - event.frame_coords_left,
        h: event.frame_coords_bottom - event.frame_coords_top
      };
    },
    editHandler() {
      if (this.item.is_deleted) return;
      this.$store.state.dialog.puppeteer_search.dossier = null;
      this.$store.state.dialog.puppeteer_search.item = this.item;
      this.$store.state.dialog.puppeteer_search.enabled = true;
    },
    viewHandler() {},
    deleteHandler() {
      if (this.item.is_deleted) return;
      this.$store
        .dispatch(this.state.Action.Delete, { id: this.item.id })
        .then(() => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        })
        .finally(() => {
          this.loadItems();
        });
    },
    refreshHandler() {
      if (this.item.is_deleted) return;
      this.$store
        .dispatch(this.state.Action.Update, { id: this.item.id })
        .then(() => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        })
        .finally(() => {
          this.loadItems();
        });
    },
    loadItems() {
      this.$store.dispatch(this.state.Action.Get);
    },
    getCaseUrl(caseFace) {
      return `#/cases/${caseFace.case}/faces/filter/` + qs.stringify({ case_in: [caseFace.case], limit: 10 });
    }
  }
};
</script>

curl 'http://172.17.47.226/case-faces/4310044688055794380/' \ -H 'Connection: keep-alive' \ -H 'Accept: application/json' \ -H 'Authorization: Token
0bf1d378502c6e74ce26d82d12a6fdc704baf0b047ace8813033aebc48526a77' \ -H 'Accept-Language: ru-RU;q=0.9, en;q=0.6, *;q=0.5' \ -H 'User-Agent: Mozilla/5.0 (X11;
Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.111 Safari/537.36' \ -H 'Origin: http://localhost:8080' \ -H 'Referer:
http://localhost:8080/' \ --compressed \ --insecure
